@import "@lora/03-base/colors";

$palette-custom: (
    light:     #fff, // White
    dark:      #000,// Black
);

$dark-mode-colors: (
    primary:             #000,
    background:          #202020,
    primary-active:      #333,
    text:                #fff,
    border:              #d8d8d8
);

$semantic-colors-custom: (
    primary:             #000,
    primary-active:      #333,
    primary-selected:    #525252,
    secondary:           #fff,
    secondary-active:    #ce0058,
    text:                #000,
    text-secondary:      #4e4e4e,
    success:             #276027,
    success-alert:       #edf9f2,
    success-light:       #46bf81,
    alert:               #ab000d,
    disabled:            #ccc,
    element-background:  #8c8c8c,
    global-background:   #f7f6f7,
    organism-background: #333,
    border:              #d8d8d8,
    grey2:               #454545,
    info:                #000,
);

$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
$palette: map-extend($palette-default, $palette-custom);